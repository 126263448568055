import { PublicProductInterface } from 'src/Interfaces/ProductPublicInterface.ts';
import { ProductInterface } from '../../../Interfaces/Product.ts';
import defaultCardUrl from '@assets/images/default_card.jpg';
import { MediaUrlKeys } from '../../../generated/interfaces/productimage.ts';

type ImageProps = {
    product: ProductInterface | PublicProductInterface;
    classNames?: string;
    type: MediaUrlKeys;
};

export default function Image({ product, classNames, type = 'sylius_large' }: ImageProps) {
    let image = product.images?.filter(image => image.type === 'thumbnail').at(0);
    if (!image && product.images) {
        image = product.images?.at(0);
    }

    return (
        <img
            src={image?.mediaUrl ? image.mediaUrl[type] : defaultCardUrl}
            alt={product.name}
            className={classNames}
        />
    );
}
