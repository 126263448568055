import 'swiper/css';
import 'swiper/css/navigation';
import { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import Image from '../../Image/Image.tsx';
import {
    PRODUCT_TYPE_ECARD,
    PRODUCT_TYPE_LICENSE_AND_LICENSE_BUNDLE,
    PRODUCT_TYPE_PHYSICAL_CARD,
    STOCK_TYPE_LICENSE,
} from '../../../../Constant.tsx';
import { ProductInterface } from '../../../../Interfaces/Product.ts';
import classNames from 'classnames';
import { productVariantsService } from '../../../../Services/Services.ts';
import { Link } from 'react-router-dom';
import SwiperCore from 'swiper';
import { Container } from '../../../Styled/CarouselBaseStyles.tsx';
import { CarouselStyles } from 'src/Components/Styled/CarouselNewProductsStyled.tsx';
import { SwiperOptions } from 'swiper/types';
import { PublicProductInterface } from 'src/Interfaces/ProductPublicInterface.ts';
import styled from 'styled-components';
import { useProductRoutePrefix } from '../../../../Context/ProductRoutePrefixContext.tsx';
import { useBasePrefix } from '../../../../Context/BasePrefixContext.tsx';
import Loader from '../../../../LoadModules/Loader';

type ProductListProps = {
    styleimported: CarouselStyles;
    breakpoints: { [width: number]: SwiperOptions };
    products?: ProductInterface[] | PublicProductInterface[] | undefined;
    isLoading?: boolean;
};

export const StyledLink = styled(Link)`
    &.no-pointer {
        cursor: default !important;
    }
`;
export default function Carousel(props: ProductListProps) {
    const basePrefix = useBasePrefix();

    const { styleimported, breakpoints, products, isLoading } = props;
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
    const [swiperTranslate, setSwiperTranslate] = useState<number>(0);
    const [prevTranslate, setPrevTranslate] = useState<number>(0);
    const prefix = useProductRoutePrefix();

    useEffect(() => {
        setSwiperTranslate(0);
        setPrevTranslate(0);
    }, [products]);

    const displayShadow = async (product: ProductInterface | PublicProductInterface) => {
        const service = new productVariantsService();

        if (product.productType?.name === PRODUCT_TYPE_LICENSE_AND_LICENSE_BUNDLE) {
            const variants = await service.getAllProductVariantsByProduct(product.code, 1, 1);
            return variants[0].stockType?.label === STOCK_TYPE_LICENSE;
        }
        return product.productType?.name === PRODUCT_TYPE_ECARD || product.productType?.name === PRODUCT_TYPE_PHYSICAL_CARD;
    };

    const swiperParams = {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        modules: [Navigation],
        breakpoints: breakpoints,
        onInit: (swiper: SwiperCore) => {
            setSwiperInstance(swiper);
        },
        onSlideChangeTransitionEnd: (swiperInstance: SwiperCore) => handleSlideChangeTransitionEnd(swiperInstance),
    };

    const handleSlideChangeTransitionEnd = (swiperInstance: SwiperCore) => {
        if (swiperInstance && swiperInstance.translate !== undefined) {
            const currentTranslate = swiperInstance.translate;
            const direction = Math.sign(currentTranslate - prevTranslate);

            setPrevTranslate(currentTranslate);

            setSwiperTranslate(direction * 50);
            setTimeout(() => {
                setSwiperTranslate(0);
            }, 250);
        }
    };

    return (
        <Container>
            <styleimported.StyledSwiperCarousel
                swipertranslate={swiperTranslate}
                {...swiperParams}
                className='mySwiper'
            >
                {isLoading ?
                    <SwiperSlide>
                        <Loader />
                    </SwiperSlide>
                :   products?.map((product: ProductInterface | PublicProductInterface, index: number) => (
                        <SwiperSlide key={`${product.code}-${index}`}>
                            <div className={'item'}>
                                <StyledLink
                                    className={`product-link ${!(product as ProductInterface).code ? 'no-pointer' : ''}`}
                                    to={(product as ProductInterface).code ? `${basePrefix}${prefix}/${product.code}` : ''}
                                >
                                    <div className={classNames('product-image', { 'stylized-product-image': displayShadow(product) })}>
                                        <Image
                                            type={'sylius_large'}
                                            product={product}
                                        />
                                    </div>
                                    <styleimported.ProductNameCarousel>{product.name}</styleimported.ProductNameCarousel>
                                </StyledLink>
                            </div>
                        </SwiperSlide>
                    ))
                }

                <styleimported.StyledButtonSwiperCarousel className='is-align-items-center'>
                    <button
                        className='swiper-button-prev'
                        onClick={() => {
                            swiperInstance?.slidePrev();
                        }}
                    />
                    <button
                        className='swiper-button-next'
                        onClick={() => {
                            swiperInstance?.slideNext();
                        }}
                    />
                </styleimported.StyledButtonSwiperCarousel>
            </styleimported.StyledSwiperCarousel>
        </Container>
    );
}
